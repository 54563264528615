import React, { ReactNode } from 'react'
import * as HeadingStyles from './Heading.module.scss'

interface HeadingProps {
  children: ReactNode
  darkMode?: boolean
}

const Heading = ({ children, darkMode = false }: HeadingProps) => {
  return (
    <div className={`${HeadingStyles.Wrapper} ${darkMode && HeadingStyles.DarkMode}`}>
      <h1>{children}</h1>
      <hr />
    </div>
  )
}

export default Heading
