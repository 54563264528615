import React, { useEffect, useState } from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import ArticleShare from '../components/ArticleShare/ArticleShare'
import Logomark from '../components/Logomark/Logomark'
import ReaderTreatForm, {
  ReaderTreatsTandCs,
  ReaderTreatsRecommended
} from '../components/layoutComponents/readerTreats/ReaderTreats'
import TopBanner from '../components/Banners/TopBanner/TopBanner'
import * as ArticleStyles from '../components/layoutComponents/article/Article.module.scss'
import * as BreadcrumbStyles from '../components/organisms/breadcrumb/Breadcrumb.module.scss'
import { Link, graphql } from 'gatsby'
import PageMeta from '../components/PageMeta/PageMeta'
import type { IReaderTreatQueryData } from '../graphql/queries/ReaderTreatQuery'
import Leaderboard from '../components/Banners/Leaderboard/Leaderboard'

export const query = graphql`
  query ($id: ID!) {
    wpgraphql {
      readerTreats(id: $id) {
        databaseId
        content
        excerpt(format: RENDERED)
        link
        termsAndConditions
        title
        requestConsent
        enterText
        question
        sponsor
        responseText
        endDate
        options
        featuredImage {
          node {
            mediaItemUrl
          }
        }
      }
      allReaderTreats(where: { notIn: [$id] }, first: 4) {
        nodes {
          link
          title
          id
          featuredImage {
            node {
              id
              mediaItemUrl
              altText
            }
          }
        }
      }
      adStarCodes {
        id
        adslot
      }
      generalSettings {
        siteId
        title
        egSettingTwitter
        url
      }
    }
  }
`

interface ReaderTreatsProps {
  data: IReaderTreatQueryData
}

const ReaderTreat = ({
  data: {
    wpgraphql: { readerTreats, allReaderTreats, adStarCodes, generalSettings }
  }
}: ReaderTreatsProps) => {
  const { endDate } = readerTreats
  const { siteId, title } = generalSettings
  const [isExpired, setIsExpired] = useState<boolean>(false)

  useEffect(() => {
    const currentDate = new Date()
    setIsExpired(Boolean(endDate && new Date(endDate) < currentDate))
  }, [])

  return (
    <>
      <PageMeta
        pageData={{
          ...generalSettings,
          title: `${readerTreats.title} | Reader Treat | Muddy Stilettos`,
          description: readerTreats.excerpt,
          url: readerTreats.link,
          image: readerTreats.featuredImage?.node?.mediaItemUrl
        }}
      />
      <Header siteId={siteId} siteName={title} />
      <TopBanner ads={adStarCodes} pageType={`readertreats`} />
      <Leaderboard adSlot={'TopLB'} sticky />
      <article>
        <div className={ArticleStyles.Wrapper}>
          <div className={ArticleStyles.Intro}>
            <div className={BreadcrumbStyles.Base}>
              <Link to={`/reader-treats/`}>Reader Treats</Link>
            </div>
            <h1
              dangerouslySetInnerHTML={{ __html: readerTreats.title ?? '' }}
            />
            <p></p>
            <div className={ArticleStyles.Share}>
              <span className={ArticleStyles.ShareText}>
                Share this Reader Treat:
              </span>{' '}
              <span className={ArticleStyles.SocialWrap}>
                <ArticleShare postLink={readerTreats.link ?? ''} />
              </span>
            </div>
          </div>
          <div className={ArticleStyles.Main}>
            <div className={ArticleStyles.Content}>
              <div
                className={ArticleStyles.ContentP}
                dangerouslySetInnerHTML={{ __html: readerTreats.content ?? '' }}
              />
            </div>
            {isExpired ? (
              <div className={ArticleStyles.ExpiredTreat}>
                Sorry, this Treat has now ended.
              </div>
            ) : (
              <ReaderTreatForm site={siteId} readerTreat={readerTreats} />
            )}
            <ReaderTreatsTandCs
              content={readerTreats.termsAndConditions ?? ''}
            />
          </div>
          <Logomark />
        </div>
        <ReaderTreatsRecommended
          relatedTreats={allReaderTreats.nodes}
          siteId={siteId ?? 1}
        />
      </article>
      <Leaderboard adSlot={'BottomLB'} />
      <Footer />
    </>
  )
}

export default ReaderTreat
