export const enterReaderTreat = async ({
  id,
  data,
}: {
  id: number
  data: Record<string, string>
}) => {
  const url = `https://dev.muddystilettos.co.uk/wp-json/muddy/rt-signup`
  const { emailaddress, county, question, consent, name } = data
  const signInData = new FormData();
  signInData.append('emailaddress', emailaddress);
  signInData.append('county', county);
  signInData.append('question', question);
  signInData.append('consent', consent);
  signInData.append('name', name);
  signInData.append('id', id.toString());
  return await fetch(url, {
    method: 'POST',
    body: signInData,
  }).then(response => {
    return response.json()
  })
  .catch(e => {
    return e.message
  })
}
