// extracted by mini-css-extract-plugin
export var Alert = "ReaderTreats-module--Alert--0149e";
export var ButtonRow = "ReaderTreats-module--ButtonRow--54c61";
export var CheckboxRow = "ReaderTreats-module--CheckboxRow--e31f8";
export var ConsentRow = "ReaderTreats-module--ConsentRow--a23e0";
export var DisableQuestion = "ReaderTreats-module--DisableQuestion--9862d";
export var Form = "ReaderTreats-module--Form--3f0cb";
export var Recommended = "ReaderTreats-module--Recommended--ebe28";
export var RowSpacer = "ReaderTreats-module--RowSpacer--8ab38";
export var ShowQuestion = "ReaderTreats-module--ShowQuestion--91b62";
export var TandCs = "ReaderTreats-module--TandCs--95a83";
export var Wrapper = "ReaderTreats-module--Wrapper--94f2f";