import React, {
  useReducer,
  useState,
  ChangeEvent,
  useContext,
  useEffect
} from 'react'
import Heading from '../../Blocks/heading/Heading'
import * as ReaderTreatStyles from './ReaderTreats.module.scss'
import * as BlockStyles from '../../Blocks/Blocks.module.scss'
import Block from '../../Blocks/block/Block'
import { Link } from 'gatsby'
import { ReaderTreats as IReaderTreats } from '../../../types'
import { enterReaderTreat } from '../../../api/readertreats'
import { LoginContext } from '../../../lib/utils'

interface ReaderTreatFormProps {
  readerTreat: IReaderTreats
  site?: number | null
}

const ReaderTreatForm = ({ readerTreat, site }: ReaderTreatFormProps) => {
  // eslint-disable-next-line node/no-unsupported-features/node-builtins
  const searchParams = new URLSearchParams(
    typeof window !== 'undefined' ? location.search : undefined
  )
  const referral = searchParams?.get('referral')
  const { usr } = useContext(LoginContext)
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [loggedIn, setLoggedIn] = useState<boolean>(false)
  const {
    requestConsent,
    question,
    sponsor,
    responseText,
    options,
    databaseId
  } = readerTreat
  const [formValues, setFormValues] = useReducer<
    (currentValues: any, newValues: any) => any
  >((currentValues, newValues) => ({ ...currentValues, ...newValues }), [])
  const formFields = ['name', 'emailaddress', 'county', 'question', 'tandcs']

  const isEnabled = (field: string) => {
    return Object.keys(formValues).includes(field)
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSubmitted(false)
    setError(null)
    const { name, value, checked } = event.target

    const enteredvalue =
      name === 'tandcs' || name === 'consent' ? (checked ? '1' : null) : value

    setFormValues({ [name]: enteredvalue })
  }

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSubmitted(false)
    setError(null)
    const { name, value } = event.target

    setFormValues({ [name]: value })
  }

  const handleFormSubmission = () => {
    setLoading(true)
    enterReaderTreat({ data: formValues, id: databaseId })
      .then(response => {
        if (response.success === false) {
          setError(response.data)
        } else {
          setSubmitted(true)
        }
      })
      .catch(() => setError('There was a problem entering this treat'))
      .finally(() => setLoading(false))
  }

  const isValid = () => {
    return !formFields.every(
      required => formValues[required] && formValues[required].length > 0
    )
  }

  useEffect(() => {
    if (usr?.viewer && usr?.viewer.name) {
      setLoggedIn(true)
      setFormValues({
        name: usr.viewer.name,
        emailaddress: usr.viewer.email,
        county: site?.toString() ?? '1'
      })
    } else {
      setLoggedIn(false)
    }
  }, [usr])

  useEffect(() => {
    if (referral && referral === 'northants-leics-rutland') {
      setFormValues({
        county: '160'
      })
    } else if (referral && referral.length > 0) {
      setFormValues({
        county: '24'
      })
    }
  }, [referral])

  return (
    <div className={ReaderTreatStyles.Wrapper}>
      <h3>Enter</h3>
      <p>
        Our Reader Treats are exclusively available to Muddy Newsletter Readers
        and Instagram followers
      </p>
      <form className={ReaderTreatStyles.Form}>
        {!loggedIn ? (
          <>
            <p>
              <label htmlFor="name">Your name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formValues.name || ''}
                onChange={handleInputChange}
              />
            </p>
            <p
              className={
                isEnabled('name')
                  ? ReaderTreatStyles.ShowQuestion
                  : ReaderTreatStyles.DisableQuestion
              }
            >
              <label htmlFor="emailaddress">Your email address</label>
              <input
                type="text"
                id="emailaddress"
                name="emailaddress"
                value={formValues.emailaddress || ''}
                onChange={handleInputChange}
              />
            </p>
            <p
              className={
                isEnabled('emailaddress')
                  ? ReaderTreatStyles.ShowQuestion
                  : ReaderTreatStyles.DisableQuestion
              }
            >
              <label htmlFor="county">Select county</label>
              <select
                name="county"
                id="county"
                value={formValues.county}
                defaultValue={
                  referral ? (referral === 'london' ? '24' : '160') : ''
                }
                onChange={handleSelectChange}
              >
                <option value="">-- Select your county</option>
                <option value="5">Berkshire</option>
                <option value="2">Herts & Beds</option>
                <option value="3">Bucks & Oxon</option>
                <option value="15">Cambridgeshire & Suffolk</option>
                <option value="9">Cornwall</option>
                <option value="14">Devon</option>
                <option value="8">Dorset & Somerset</option>
                <option value="22">Essex</option>
                <option value="17">Hants & IOW</option>
                <option value="13">Worcs & Gloucs</option>
                <option value="10">Kent</option>
                <option value="160">Northants, Leics & Rutland</option>
                <option value="24">London</option>
                <option value="11">Sussex</option>
                <option value="23">Derby & Notts</option>
                <option value="12">Norfolk</option>
                <option value="7">Surrey</option>
                <option value="18">Warks & West Midlands</option>
                <option value="19">Wiltshire</option>
              </select>
            </p>
          </>
        ) : (
          <>
            <p>
              You are currently logged in as{' '}
              <span className={ReaderTreatStyles.LoggedInAs}>
                {usr?.viewer.name}
              </span>
            </p>
          </>
        )}
        <p
          className={
            (isEnabled('county') && isEnabled('emailaddress')) || loggedIn
              ? ReaderTreatStyles.ShowQuestion
              : ReaderTreatStyles.DisableQuestion
          }
        >
          <label htmlFor="question">{question}</label>
          <select
            name="question"
            id="question"
            value={formValues.question}
            onChange={handleSelectChange}
          >
            <option value="">-- Select</option>
            {options &&
              options.map((option, id) => (
                <option value={option ?? ''} id={id.toString()} key={id}>
                  {option}
                </option>
              ))}
          </select>
        </p>
        {requestConsent && (
          <p
            className={`${
              isEnabled('question')
                ? ReaderTreatStyles.ShowQuestion
                : ReaderTreatStyles.DisableQuestion
            } ${ReaderTreatStyles.ConsentRow}`}
          >
            <label htmlFor="consent">
              <strong>
                Would you like to be sent news and offers from {sponsor}?
              </strong>
              <br />
              (We’ll only ever share your email with your consent)
            </label>
            <input
              type="checkbox"
              id="consent"
              name="consent"
              value="1"
              defaultChecked={formValues.consent}
              onChange={handleInputChange}
            />
          </p>
        )}
        <p
          className={`${
            isEnabled('question')
              ? ReaderTreatStyles.ShowQuestion
              : ReaderTreatStyles.DisableQuestion
          } ${ReaderTreatStyles.CheckboxRow}`}
        >
          <label htmlFor="tandcs">I accept the Terms and Conditions</label>
          <input
            type="checkbox"
            id="tandcs"
            name="tandcs"
            defaultChecked={formValues.tandcs}
            onChange={handleInputChange}
          />
        </p>
        <p className={ReaderTreatStyles.ButtonRow}>
          <button
            type="button"
            onClick={() => handleFormSubmission()}
            disabled={submitted || isValid()}
          >
            {loading ? <>Submitting</> : 'Enter the Reader Treat'}
          </button>
          {submitted ||
            (error && (
              <span className={ReaderTreatStyles.Alert}>
                {error ? error : `Thanks for entering ${responseText}`}
              </span>
            ))}
          {submitted && (
            <span className={ReaderTreatStyles.Alert}>{`${
              responseText || 'Huzzah, you have now entered!'
            }`}</span>
          )}
        </p>
      </form>
    </div>
  )
}

interface ReaderTreatsTandCsProps {
  content: string
}

export const ReaderTreatsTandCs = ({ content }: ReaderTreatsTandCsProps) => {
  return (
    <div className={ReaderTreatStyles.TandCs}>
      <h2>Need to Know</h2>
      <span dangerouslySetInnerHTML={{ __html: content }} />
      <p>
        Read our full{` `}
        <Link
          to={`https://muddystilettos.co.uk/competitions-offers-terms-conditions/`}
        >
          Reader Treats Terms and Conditions
        </Link>
        {` `}here.
      </p>
    </div>
  )
}

interface ReaderTreatsRecommendedProps {
  relatedTreats: IReaderTreats[]
  siteId: number
}

export const ReaderTreatsRecommended = ({
  relatedTreats,
  siteId
}: ReaderTreatsRecommendedProps) => {
  const linkFix = (link: string) => {
    const subDomain = link.split('.')[0].replace('https://', '')
    const directory = link.split('/')[3]

    return siteId === 1
      ? link.replace(`//${subDomain}.`, `//`)
      : link
          .replace(`//${subDomain}.`, `//${directory}.`)
          .replace(`/${directory}/`, `/`)
  }

  return (
    <div>
      <Heading>More Reader Treats</Heading>
      <div className={`${BlockStyles.Cols} ${BlockStyles.NoPadding}`}>
        <div
          className={`${BlockStyles.Col} ${ReaderTreatStyles.RowSpacer} ${BlockStyles.FullWidth}`}
        >
          {relatedTreats &&
            relatedTreats.map((article, index) => (
              <Block
                id={article.id}
                key={index}
                image={article.featuredImage?.node?.mediaItemUrl}
                uri={linkFix(article.link ?? '')}
                title={article.title}
                linkType={'external'}
              />
            ))}
        </div>
      </div>
    </div>
  )
}

export default ReaderTreatForm
